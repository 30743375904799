<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Первичные группы</h1>
      <div class="content__group">
        <button @click="onExport" type="button" class="link">
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word</span>
        </button>
        <router-link v-if="userRole === 'admin'" :to="{ name: 'primary-stat' }" class="link link--right" type="button">
          <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
          <span>Статистика</span>
        </router-link>
      </div>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="content__title">
      <router-link :to="{ name: 'primary-create' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить группу</span>
      </router-link>
    </div>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default"
    >
      <div slot="name" slot-scope="props" class="table-default__left">
        {{ props.row.name }}
      </div>
      <div slot="head_name" slot-scope="props" class="table-default__left">
        {{ props.row.head_name }}
      </div>
      <div slot="head_phone" slot-scope="props">
        {{ props.row.head_phone || '-' }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z" fill="#2B93E7"/>
              <path d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z" fill="#2B93E7"/>
              <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img
                  v-if="userRole !== 'admin' && (props.row.status === 'review' || props.row.status === 'accepted')"
                  src="@/assets/img/view-icon.svg"
                  alt="View icon"
                >
                <img v-else src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <router-link
                  :to="{name: 'primary-edit', params: { id: props.row.id }}"
                  class="tooltip__btn"
                >
                  <span
                    v-if="userRole !== 'admin' && (props.row.status === 'review' || props.row.status === 'accepted')"
                  >
                    Смотреть
                  </span>
                  <span v-else>Редактировать</span>
                </router-link>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="deleteItem(props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'

export default {
  name: 'PrimaryList',
  components: { dropdown },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'name', 'date', 'head_name', 'head_phone', 'status_name', 'count', 'tooltip'],
        options: {
          headings: {
            id: 'ID',
            name: 'Наименование первичной группы',
            date: 'Дата создания',
            head_name: 'ФИО руководителя',
            head_phone: 'Контактный телефон',
            status_name: 'Статус',
            count: 'Количество членов РСМ',
            tooltip: '',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
    };
  },
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    this.fetchItems(1, this.limitSelected.name)
  },
  methods: {
    fetchItems(page = 1, limit) {
      this.$store.dispatch('primary/GET_LIST', { page, limit })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    deleteItem(id) {
      this.$store.dispatch('primary/DELETE_DATA', id)
        .then(() => {
          document.body.click()
          this.fetchItems(1, this.limitSelected.name)
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Первичная группа удалена.'
          })
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    onExport() {
      this.$store.dispatch('primary/GET_EXPORT')
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Экспорт_ПГ.docx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
};
</script>
